import IMG1 from '../../assets/The-Method.webp'
import IMG2 from '../../assets/battle-buddies.webp'
import IMG3 from '../../assets/TaskMate.svg'
import IMG4 from '../../assets/GiveHope.svg'
import IMG5 from '../../assets/Landing.svg'

const data = [
    {
        id: 1,
        image: IMG1,
        title: "The Method Music Player",
        description: "Offline music player (C# - WINFORMS)",
        github: "https://github.com/mustafazeydani/The-Method-Music-Player",
    },  
    {
        id: 2,
        image: IMG2,
        title: "Battle Buddies",
        description: "A 2D card fighting game (Vanilla JS)",
        github: "https://github.com/mustafazeydani/battle-buddies",
        livedemo: "https://mustafazeydani.github.io/Battle-Buddies/",
    },
    {
        id: 3,
        image: IMG3,
        title: "TaskMate",
        description: "A task management web application (SERN stack)",
        github: "https://github.com/Iskenderun-Technical-University/mustafazeydani-VDGP",
    },
    {
        id: 4,
        image: IMG4,
        title: "GiveHope",
        description: "A donation management web application (SERN stack)",
        github: "https://github.com/mustafazeydani/GiveHope",
    },
    {
        id: 5,
        image: IMG5,
        title: "Landing Page Components",
        description: "Components for different landing page designs and sections using React and Tailwind CSS",
        github: "https://github.com/mustafazeydani/landing-page-components",
        livedemo: "https://mustafazeydani.github.io/landing-page-components/"
    }
]

export default data.reverse()