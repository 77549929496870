import React from "react";
import "./skills.css";
import HTML5 from "../../assets/tech-icons/html5.svg";
import CSS3 from "../../assets/tech-icons/css3.svg";
import JavaScript from "../../assets/tech-icons/javascript.svg";
import ReactJS from "../../assets/tech-icons/reactjs.svg";
import TailWindCSS from "../../assets/tech-icons/tailwindcss.svg";
import NextJS from "../../assets/tech-icons/nextjs.svg";
import Vite from "../../assets/tech-icons/vite.svg";
import NodeJS from "../../assets/tech-icons/nodejs.svg";
import Express from "../../assets/tech-icons/express.svg";
import VS from "../../assets/tech-icons/vs.svg";
import Git from "../../assets/tech-icons/git.svg";
import GitHub from "../../assets/tech-icons/github.svg";
import Figma from "../../assets/tech-icons/figma.svg";
import Firebase from "../../assets/tech-icons/firebase.svg";
import MongoDB from "../../assets/tech-icons/mongodb.svg";
import NPM from "../../assets/tech-icons/npm.svg";
import MySQL from "../../assets/tech-icons/mysql.svg";
import Netlify from "../../assets/tech-icons/netlify.svg";
import Heroku from "../../assets/tech-icons/heroku.svg";
import Vercel from "../../assets/tech-icons/vercel.svg";
import Expo from "../../assets/tech-icons/expo.svg";

import { BsCodeSlash } from "react-icons/bs";
import { BsPenFill } from "react-icons/bs";
import { AiFillMobile } from "react-icons/ai";

const Experience = () => {
  const tech = [
    HTML5,
    CSS3,
    JavaScript,
    ReactJS,
    TailWindCSS,
    NextJS,
    Vite,
    NodeJS,
    Express,
    VS,
    Git,
    GitHub,
    Figma,
    Firebase,
    MongoDB,
    NPM,
    MySQL,
    Netlify,
    Heroku,
    Vercel,
    Expo,
  ];
  return (
    <section id="skills" data-aos="fade-right">
      <h5>Experience I Have</h5>
      <h2>Skills</h2>
      <h3>Tech Stack</h3>
      <div className="tech-stack">
        {tech.map((icon, index) => {
          return (
            <div
              className="tech-stack-item"
              data-aos="fade-up"
              data-aos-delay={index * 100}
              key={index}
            >
              <img src={icon} alt="tech-icon" />
            </div>
          );
        })}
      </div>
      <h3>Services</h3>
      <div className="services">
        <div className="services-item">
          <BsCodeSlash className="icon" size={40} />
          <h4>Web Development</h4>
          <p>
            Building responsive and modern websites with the latest technologies
            using HTML, CSS, JavaScript, ReactJS, NextJS, TailwindCSS, NodeJS,
            MongoDB, MySQL, Firebase, Netlify, Vercel, Heroku, etc.
          </p>
        </div>
        <div className="services-item">
          <BsPenFill className="icon" size={40} />
          <h4>Web Design</h4>
          <p>
            Designing modern websites using Figma, Adobe Photoshop, Adobe
            Illustrator, etc.
          </p>
        </div>
        <div className="services-item">
          <AiFillMobile className="icon" size={40} />
          <h4>Mobile App Development</h4>
          <p>Building mobile apps using React Native, Expo, etc.</p>
        </div>
      </div>
    </section>
  );
};

export default Experience;
