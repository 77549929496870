import { createRoot } from "react-dom/client";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);

AOS.init();
