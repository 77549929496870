import React from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'

	export const Contact = () => {
		const form = useRef();
	
		const sendEmail = (e) => {
		e.preventDefault();
	
		emailjs.sendForm('service_7pvjjzh', 'template_5jtcfor', form.current, 'OpoeB084dRWVw160E')
			.then((result) => {
				console.log(result.text);
				alert("Message Sent!");
			}, (error) => {
				console.log(error.text);
			});
			e.target.reset()
	};

	return (
		<section id="contact" data-aos="fade-right">
			<h5>Get In Touch</h5>
			<h2>Contact</h2>

			<div className="container contact_container">
				<div className="contact_options">
					<article className="contact_option">
						<MdOutlineEmail className="contact_option_icon"/>
						<h4>Email</h4>
						<h5>mustafamari20@gmail.com</h5>
						<a href="mailto:mustafamari20@gmail.com" target="_blank" rel='noreferrer'>Send an email</a>
					</article>

					<article className="contact_option">
						<RiMessengerLine className="contact_option_icon"/>
						<h4>Messenger</h4>
						<h5>Mustafa Zeydani</h5>
						<a href="https://ig.me/m/mustafa.zeydani" target="_blank" rel='noreferrer'>Send a message</a>
					</article>

					<article className="contact_option">
						<BsWhatsapp className="contact_option_icon"/>
						<h4>Whatsapp</h4>
						<h5>+90 553 844 94 50</h5>
						<a href="https://wa.me/+905538449450" target="_blank" rel='noreferrer'>Send a message</a>
					</article>
				</div>
				<form ref={form} onSubmit={sendEmail}>
					<input type="text" name="name" placeholder="Your Full Name" required/>
					<input type="email" name="email" placeholder="Your Email" required/>
					<textarea name="message" rows="7" placeholder="Your Message" required></textarea>
					<button type="submit">Send Message</button>
				</form>
			</div>
		</section>
	)
}

export default Contact