import React from "react";
import "./about.css";
import AboutSocials from "./AboutSocials";

const About = () => {
  return (
    <section id="about" data-aos="fade-right">
      <h2>About Me</h2>
      <div className="row">
        <div className="flex_container about_container">
          <AboutSocials />

          <div className="languages_container">
            <div className="languages">
              <h2>My Languages</h2>
              <div className="l1">
                <h3>Arabic</h3>
                <div>Native</div>
              </div>
              <div className="l2">
                <h3>English</h3>
                <div>Full professional proficiency</div>
              </div>
              <div className="l3">
                <h3>Turkish</h3>
                <div>Professional proficiency</div>
              </div>
            </div>
          </div>

          <div className="description_container">
            <p>
              My name is Mustafa. I'm 24 years old. I'm a Computer Engineering
              student at İSTE and a junior Full Stack Web developer. I have
              intermediate skills in different web development programming
              languages and frameworks and I'm always learning new technologies
              to improve my skills. I'm also interested in mobile app
              development. In addition to my technical skills, I enjoy
              collaborating with peers and participating in coding projects,
              fostering a passion for teamwork and innovation in the field of
              technology.
            </p>
            <a href="#contact" className="btn">
              Contact Me
            </a>
          </div>

          <div className="signature">
            <a href="#contact">Mustafa Zeydani</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
