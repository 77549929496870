import React from "react";
import "./education.css";

const Education = () => {
  return (
    <section id="education" data-aos="fade-right">
      <h2>Education</h2>
      <div className="flex_container timeline_container">
        <div className="year">
          <div className="dot"></div>
          <span>2021</span>
          <div className="wrapper_edu">
            <h3>Computer Engineering</h3>
            <p>İskenderun Technical University (2021-2025)</p>
          </div>
        </div>
        <div className="year">
          <div className="dot"></div>
          <span>2022</span>
        </div>
        <div className="year">
          <div className="dot"></div>
          <span>2023</span>
        </div>
        <div className="year">
          <div className="dot">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <span>2024</span>
        </div>
        <div className="year">
          <div className="dot"></div>
          <span>2025</span>
        </div>
      </div>
    </section>
  );
};

export default Education;
