import React, {useEffect} from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {AiFillEye} from 'react-icons/ai'
import './footer.css'
import axios from 'axios'

const Footer = () => {
	useEffect(() => {
		let apiLink = `https://api.api-ninjas.com/v1/counter?id=${process.env.REACT_APP_COUNTER_ID}`
		if(!localStorage.getItem("Visited")) {
			apiLink += '&hit=true'
			localStorage.setItem("Visited", "True")
		} 
		axios.get(apiLink, {
			headers: {
				'X-Api-Key': process.env.REACT_APP_API_KEY
			}
		}).then(res => {
			document.getElementById('visits').innerHTML = res.data.value
		}).catch(err => {
			console.log(err)
		})
	}, [])

	return (
		<footer id="footer">
			<ul className="permalinks">
				<li><a href="#home">Home</a></li>
				<li><a href="#about">About</a></li>
				<li><a href="#education">Education</a></li>
				<li><a href="#skills">Skills</a></li>
				<li><a href="#projects">Projects</a></li>
				<li><a href="#activity">Activity</a></li>
				<li><a href="#contact">Contact</a></li>
			</ul>

			<div className="footer_socials">
				<a href="https://www.linkedin.com/in/mustafa-zeydani/" target="_blank" rel='noreferrer'><BsLinkedin className="footer_logo"/></a>
				<a href="https://www.instagram.com/mustafa.zeydani/" target="_blank" rel='noreferrer'><BsInstagram className="footer_logo"/></a>
				<a href="https://github.com/mustafazeydani" target="_blank" rel='noreferrer'><FaGithub className="footer_logo"/></a>
				<a href="https://twitter.com/mustafa_zeydani" target="_blank" rel='noreferrer'><BsTwitter className="footer_logo"/></a>
			</div>

			<div className="views">
				<AiFillEye className="footer_logo"/>
				<div id="visits">-</div>
			</div>

			<div className="footer_copyrights">
				<small>&copy; Mustafa Zeydani. All rights reserved.</small>
			</div>
		</footer>
	)
}

export default Footer