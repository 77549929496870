import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {TbBooks} from 'react-icons/tb'
import {MdOutlineLibraryBooks} from 'react-icons/md'
import {BiMessageDetail} from 'react-icons/bi'
import {HiCode} from 'react-icons/hi'
import {useState} from 'react'

const Nav = () => {
	const [activeNav, setActiveNav] = useState('#home')
	return (
		<nav>
			<div className="navigation">
				<ul>
					<li onClick={() => setActiveNav('#home')} className={activeNav === '#home' ? 'active' : ''}>
						<a href="#home">
							<span className="icon"><AiOutlineHome /></span>	
							<span className="text">Home</span>	
						</a>
					</li>

					<li onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''} >
						<a href="#about">
							<span className="icon"><AiOutlineUser /></span>	
							<span className="text">About</span>	
						</a>
					</li>

					<li onClick={() => setActiveNav('#education')} className={activeNav === '#education' ? 'active' : ''}>
						<a href="#education">
							<span className="icon"><TbBooks /></span>	
							<span className="text">Education</span>	
						</a>
					</li>

					<li onClick={() => setActiveNav('#skills')} className={activeNav === '#skills' ? 'active' : ''}>
						<a href="#skills">
							<span className="icon"><HiCode /></span>	
							<span className="text">Skills</span>	
						</a>
					</li>

					<li onClick={() => setActiveNav('#projects')} className={activeNav === '#projects' ? 'active' : ''}>
						<a href="#projects">
							<span className="icon"><MdOutlineLibraryBooks /></span>	
							<span className="text">Projects</span>	
						</a>
					</li>

					<li onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
						<a href="#contact">
							<span className="icon"><BiMessageDetail /></span>	
							<span className="text">Contact</span>	
						</a>
					</li>

					<div className="indicator"></div>
				</ul>
			</div>
		</nav>
	)
}

export default Nav