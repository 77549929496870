import React, { useState, useEffect, useLayoutEffect } from "react";
import Header from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Education from "./components/education/Education";
import Skills from "./components/skills/Skills";
import Projects from "./components/projects/Projects";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Loader from "./components/loader/Loader";
import meImage from "./assets/me.webp";
import meColoredImage from "./assets/meColored.webp";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [preloadedMe, setPreloadedMe] = useState(null);
  const [preloadedMeColored, setPreloadedMeColored] = useState(null);

  // Preload images for faster webpage
  const preloadImage = (url, setImageState) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageState(img);
    };
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationFinished(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    if (document.readyState === "complete") {
      setLoading(false);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useLayoutEffect(() => {
    preloadImage(meImage, setPreloadedMe);
    preloadImage(meColoredImage, setPreloadedMeColored);
  }, []);

  return (
    <>
      {loading || !animationFinished ? (
        <Loader />
      ) : (
        <>
          <Header meImage={preloadedMe} meColoredImage={preloadedMeColored} />
          <Nav />
          <About />
          <Education />
          <Skills />
          <Projects />
          <Contact />
          <Footer />
        </>
      )}
    </>
  );
};

export default App;
