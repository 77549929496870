import React from "react";
import "./projects.css";
import data from "./data";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <section id="projects" data-aos="fade-right">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>
      <div className="container projects_container">
        {data.map((project) => {
          const { id, image, title, description, github, livedemo } = project;
          return (
            <article key={id} className="projects_item">
              <div className="projects_item_img">
                <img src={image} alt={title} />
              </div>
              <div className="info">
                <h3>{title}</h3>
                {description && <p>{description}</p>}
              </div>
              <div className="projects_item_cta">
                {github && (
                  <Link
                    to={github}
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </Link>
                )}
                {livedemo && (
                  <Link
                    to={livedemo}
                    className="btn btn_primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Live Demo
                  </Link>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
