import React, { useState } from "react";
import "./header.css";

import { Typewriter } from "react-simple-typewriter";

const Header = ({ meImage, meColoredImage }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <header id="home">
      <div className="left-container">
        <div className="left-container-text">
          <p className="first-text">&lt;!-- Hi! --&gt;</p>
          <p className="second-text">
            Im a{" "}
            <span className="second-text-word">
              <Typewriter
                words={["Developer", "Designer", "Programmer"]}
                cursor
                loop={0}
                cursorStyle="_"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </p>
        </div>
        <div className="left-container-buttons">
          <a href="MUSTAFA_ZEYDANI_CV.pdf" className="btn" target="_blank">
            Download CV
          </a>
          <a href="#contact" className="btn btn_primary">
            Hire Me
          </a>
        </div>
      </div>
      <div
        className="right-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={
            isHovered
              ? meImage
                ? meImage.src
                : null
              : meColoredImage
              ? meColoredImage.src
              : null
          }
          alt="me"
        />
      </div>
    </header>
  );
};

export default Header;
